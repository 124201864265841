import store from '@/store';
//import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Mutations } from '@/store/enums/StoreEnums';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
      },
      {
        path: "/forgot-pass",
        name: "forgot-pass",
        component: () => import("@/views/authentication/ForgotPassword.vue"),
      },
      {
        path: "/passwordReset/:token",
        name: "passwordReset",
        component: () => import("@/views/authentication/ResetPassword.vue"),
      },
      {
        path: "/resetPassword/:token",
        name: "resetPassword",
        component: () => import("@/views/authentication/ResetPassword.vue"),
      },
      {
        path: "/password-expire-reset",
        name: "ExpirePasswordReset",
        component: () =>
          import("@/views/authentication/ResetExpirePassword.vue"),
      },
      {
        path: "/sign-switch",
        name: "sign-switch",
        component: () => import("@/views/authentication/Switcher.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/authentication/SignUp.vue"),
      },
    ],
  },
  {
    path: "/entity-organogram",
    name: "entityorganogram",
    component: () => import("@/layout/EntityOrganogram.vue"),
    children: [
      {
        path: "organogramview",
        name: "organogram view",
        component: () => import("@/views/EntityOrganogram/Tree.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/dash",
    component: () => import("@/layout/DashBoard.vue"),
    children: [
      {
        path: "/dash",
        name: "dashboard",
        component: () => import("@/views/DashBoard/Dashboard.vue"),
      },

      {
        path: "/dash-association",
        name: "dash-association",
        component: () => import("@/views/DashBoardAssociation/Dashboard.vue"),
      },
      {
        path: "/dash-institute",
        name: "institute dashboard",
        component: () => import("@/views/DashBoardInstitute/Dashboard.vue"),
      },
      {
        path: "/dash-user",
        name: "user dashboard",
        component: () => import("@/views/DashBoardUser/Dashboard.vue"),
      },
      {
        path: "/association-list",
        name: "association-list",
        component: () => import("@/views/Pending/AssociationList.vue"),
      },
      {
        path: "/institute-list",
        name: "institute-list",
        component: () => import("@/views/Pending/InstituteList.vue"),
      },
      {
        path: "/course-list",
        name: "course-list",
        component: () => import("@/views/Pending/CourseList.vue"),
      },
      {
        path: "/bill-list",
        name: "bill-list",
        component: () => import("@/views/Pending/BillList.vue"),
      },
      {
        path: "/map-trainer-institute-list",
        name: "map-trainer-institute-list",
        component: () => import("@/views/Pending/TrainerWithInstituteList.vue"),
      },
      {
        path: "/map-trainer-course-list",
        name: "map-trainer-course-list",
        component: () => import("@/views/Pending/TrainerWithCourseList.vue"),
      },
      {
        path: "/map-assessore-institute-list",
        name: "map-assessore-institute-list",
        component: () =>
          import("@/views/Pending/AssessoreWithInstituteList.vue"),
      },
      {
        path: "/map-assessore-course-list",
        name: "map-assessore-course-list",
        component: () => import("@/views/Pending/AssessoreWithCourseList.vue"),
      },
      {
        path: "/pending-trainer-list",
        name: "pending-trainer-list",
        component: () => import("@/views/Pending/TrainerList.vue"),
      },
      {
        path: "/pending-assessore-list",
        name: "pending assessore list",
        component: () => import("@/views/Pending/AssessoreList.vue"),
      },
      {
        path: "/pending-trainee-list",
        name: "pending trainee list",
        component: () => import("@/views/Pending/TraineeList.vue"),
      },
      {
        path: "/course-summary/:is_motodriving/:tranche/:entity",
        name: "course symmary by association",
        component: () =>
          import("@/views/DashBoard/summary/CourseSummaryAssociation.vue"),
      },
      {
        path: "/institute-summary/:is_motodriving/:tranche/:entity",
        name: "institute wise training summary",
        component: () =>
          import("@/views/DashBoard/summary/InstituteSummaryAssociation.vue"),
      },
      {
        path: "/course-institute-summary/:is_motodriving/:tranche/:entity/:course",
        name: "course institute wise training summary",
        component: () =>
          import("@/views/DashBoard/summary/InstituteSummaryCourse.vue"),
      },
      {
        path: "/batch-summary/:is_motodriving/:tranche/:entity/:course/:institute",
        name: "batch wise training summary",
        component: () =>
          import("@/views/DashBoard/summary/BatchwiseSummaryAssociation.vue"),
      },
      {
        path: "/institute-course-summary/:is_motodriving/:tranche/:entity/:institute",
        name: "institute wise course training summary",
        component: () =>
          import("@/views/DashBoard/summary/CourseSummaryInstitute.vue"),
      },
    ],
  },
  //Association
  {
    path: "/association",
    name: "association",
    component: () => import("@/layout/Association.vue"),
    children: [
      {
        path: "entity-list/",
        name: "entity list",
        component: () => import("@/views/Association/EntityList.vue"),
      },
      {
        path: "entity-list/:id",
        name: "single entity",
        component: () => import("@/views/Association/SingleEntity.vue"),
      },
      {
        path: "entity-employee/:id",
        name: "entity employee",
        component: () => import("@/views/Association/EntityEmployee.vue"),
      },
      {
        path: "entityEmployeeProfiles/:entity/viewProfile/:id",
        name: "entityEmployeeProfiles",
        component: () =>
          import("@/views/Association/EntityEmployeeProfile.vue"),
      },
      {
        path: "dynamic-entity-list",
        name: "dynamic entity list",
        component: () => import("@/views/Association/DynamicEntityList.vue"),
      },
      {
        path: "new-entity",
        name: "new entity",
        component: () => import("@/views/Association/NewEntity.vue"),
      },
      {
        path: "association-contracts",
        name: "association contracts",
        component: () => import("@/views/Association/AssociationContracts.vue"),
      },
      {
        path: "association-reg",
        name: "association-reg",
        component: () => import("@/views/Association/AddAssociation.vue"),
      },
      {
        path: "entity-target",
        name: "entity target",
        component: () => import("@/views/Association/EntityTarget.vue"),
      },
      {
        path: "pending-target",
        name: "pending target",
        component: () => import("@/views/Association/PendingTarget.vue"),
      },
      {
        path: "target-list",
        name: "target list",
        component: () => import("@/views/Association/TargetList.vue"),
      },
    ],
  },
  //General User
  {
    path: "/user",
    component: () => import("@/layout/UserProfile.vue"),
    children: [
      {
        path: "show-profile",
        name: "show profile",
        component: () => import("@/views/user/ShowProfile.vue"),
      },
      {
        path: "update-profile",
        name: "update profile",
        component: () => import("@/views/user/UpdateProfile.vue"),
      },
      {
        path: "change-password",
        name: "change password",
        component: () => import("@/views/user/ChangePassword.vue"),
      },
    ],
  },
  //Trainer
  {
    path: "/trainer",
    component: () => import("@/layout/Trainer.vue"),
    children: [
      {
        path: "add-trainer",
        name: "add trainer",
        component: () => import("@/views/Trainer/AddTrainer.vue"),
      },
      {
        path: "trainer-list",
        name: "trainer list",
        component: () => import("@/views/Trainer/List.vue"),
      },
      {
        path: "inactive-trainer-list",
        name: "inactive trainer list",
        component: () => import("@/views/Trainer/InactiveList.vue"),
      },
      {
        path: "trainer-search",
        name: "trainer-search-name",
        component: () => import("@/views/Trainer/TrainerSearch.vue"),
      },
      {
        path: "trainer-report",
        name: "trainer-report",
        component: () => import("@/views/Trainer/TrainerReport.vue"),
      },
    ],
  },
  //Assessor
  {
    path: "/assessor",
    component: () => import("@/layout/Assessor.vue"),
    children: [
      {
        path: "add-assessor",
        name: "add assessor",
        component: () => import("@/views/Assessor/AddAssessor.vue"),
      },
      {
        path: "assessor-organization",
        name: "assessor organization",
        component: () => import("@/views/Assessor/Organization.vue"),
      },
      {
        path: "assessor-list",
        name: "assessor list",
        component: () => import("@/views/Assessor/List.vue"),
      },
      {
        path: "assessor-view/:id",
        name: "assessor view",
        component: () => import("@/views/Assessor/ListView.vue"),
      },
      {
        path: "inactive-assessor-list",
        name: "inactive assessor list",
        component: () => import("@/views/Assessor/InactiveList.vue"),
      },
      {
        path: "assessor-search",
        name: "assessor-search-name",
        component: () => import("@/views/Assessor/AssessorSearch.vue"),
      },

      // {
      //   path: "setting",
      //   name: "setting",
      //   component: () => import("@/layout/Assessor.vue"),
      //   children: [
      //     {
      //       path: "assessor-organization",
      //       name: "assessor organization",
      //       component: () => import("@/views/Assessor/Organization.vue"),
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "/trainee",
    component: () => import("@/layout/Trainee.vue"),
    children: [
      {
        path: "new-trainee",
        name: "new trainee",
        component: () => import("@/views/trainee/new.vue"),
      },
      {
        path: "search-trainee",
        name: "search trainee",
        component: () => import("@/views/trainee/search.vue"),
      },
      {
        path: "show-trainee",
        name: "Show Trainee",
        component: () => import("@/views/trainee/show.vue"),
      },
      {
        path: "view-trainee/:id",
        name: "view trainee",
        component: () => import("@/views/trainee/view.vue"),
      },
      {
        path: "update",
        name: "upload Trainee photo",
        component: () => import("@/views/trainee/update.vue"),
      },
      {
        path: "advance-search",
        name: "advance-search",
        component: () => import("@/views/trainee/AdvanceSearch.vue"),
      },
      {
        path: "name-search",
        name: "name-search",
        component: () => import("@/views/trainee/name-search.vue"),
      },

      {
        path: "income-certificate",
        name: "income-certificate",
        component: () => import("@/views/trainee/IncomeCertificate.vue"),
        children: [
          {
            path: "required",
            name: "required income certificate",
            component: () =>
              import("@/views/trainee/income-certificate/required.vue"),
          },
          {
            path: "upload",
            name: "upload income certificate",
            component: () =>
              import("@/views/trainee/income-certificate/upload.vue"),
          },
          {
            path: "status",
            name: "income certificate status",
            component: () =>
              import("@/views/trainee/income-certificate/status.vue"),
          },
        ],
      },
      // Trainne Details
      {
        path: "trainee-details/:id",
        name: "Trainee Details",
        component: () => import("@/views/Trainne/TraineeInformation.vue"),
      },
      {
        path: "trainee-attendance-status/:id/:ispresent",
        name: "Trainee Attendance Status",
        component: () => import("@/views/Trainne/TraineeAttendanceStatus.vue"),
      },
    ],
  },

  {
    path: "/course",
    component: () => import("@/layout/Course.vue"),
    children: [
      {
        path: "course-list",
        name: "course list",
        component: () => import("@/views/course/search.vue"),
      },
      {
        path: "searchCourse",
        name: "searchCourse",
        component: () => import("@/views/course/searchCourse.vue"),
      },
      {
        path: "uniqueCourse",
        name: "uniqueCourse",
        component: () => import("@/views/course/uniqueCourse.vue"),
      },
      {
        path: "activityReport",
        name: "activityReport",
        component: () => import("@/views/course/activityReport.vue"),
      },

      {
        path: "courseInfo",
        name: "New entity course",
        component: () => import("@/views/course/new.vue"),
      },

      {
        path: "edit-course/:id",
        name: "edit-course",
        component: () => import("@/views/course/Editcourse.vue"),
      },
      {
        path: "course-detail/:id",
        name: "courseProfileDetails",
        component: () => import("@/views/course/CourseDetails.vue"),
      },
      {
        path: "mapping/trainer",
        name: "map trainer course component",

        component: () => import("@/views/course/mapping/MapTrainer.vue"),
      },
      {
        path: "mapping/assessor",
        name: "map-assessor-course-component",
        component: () => import("@/views/course/mapping/MapAssessor.vue"),
      },
    ],
  },
  // {
  //   path: '/course',
  //   component: () => import('@/layout/Course.vue'),
  //   children: [
  //     {
  //       path: 'search-course',
  //       name: 'Search course',
  //       component: () => import('@/views/course/search.vue'),
  //     },
  //     {
  //       path: 'courseInfo',
  //       name: 'New entity course',
  //       component: () => import('@/views/course/new.vue'),
  //     },
  //     {
  //       path: 'running-course',
  //       name: 'running course',
  //       component: () => import('@/views/course/RunningCourse.vue'),
  //     },
  //     {
  //       path: 'motor-driving-list',
  //       name: 'motor driving list',
  //       component: () => import('@/views/course/MotorDriving.vue'),
  //     },
  //     {
  //       path: 'category-wise-course',
  //       name: 'category wise course',
  //       component: () => import('@/views/course/CategoryWishCourse.vue'),
  //     },
  //     {
  //       path: 'mapping',
  //       name: 'course mapping',
  //       component: () => import('@/views/course/CourseMap.vue'),
  //       children: [
  //         {
  //           path: 'trainer',
  //           name: 'map course trainer',
  //           component: () => import('@/views/course/mapping/MapTrainer.vue'),
  //         },
  //         {
  //           path: 'assessor',
  //           name: 'map course assessor',
  //           component: () => import('@/views/course/mapping/MapAssessor.vue'),
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: "/course-batch",
    component: () => import("@/layout/CourseBatch.vue"),
    children: [
      {
        path: "search",
        name: "Search batch",
        component: () => import("@/views/course-batch/search.vue"),
      },
      {
        path: "batchInfo",
        name: "New batch info",
        component: () => import("@/views/course-batch/new.vue"),
      },
      {
        path: "view/:id",
        name: "batch view",
        component: () => import("@/views/course-batch/batchView.vue"),
      },
      {
        path: "running-batch",
        name: "running batch report",
        component: () => import("@/views/course-batch/RunningBatch.vue"),
      },
      {
        path: "advanced-search",
        name: "advanced-search-batch",
        component: () => import("@/views/course-batch/AdvanceSearch.vue"),
      },

      {
        path: "mapping",
        name: "course batch mapping",
        component: () => import("@/views/course-batch/BatchMap.vue"),
        children: [
          {
            path: "trainer",
            name: "map batch trainer",
            component: () =>
              import("@/views/course-batch/mapping/MapTrainer.vue"),
          },
          {
            path: "assessor",
            name: "map batch assessor",
            component: () =>
              import("@/views/course-batch/mapping/MapAssessor.vue"),
          },
          {
            path: "trainee",
            name: "map batch trainee",
            component: () =>
              import("@/views/course-batch/mapping/MapTrainee.vue"),
          },
        ],
      },
    ],
  },

  //settings
  {
    path: "/settings",
    component: () => import("@/layout/Settings.vue"),
    children: [
      {
        path: "mod",
        name: "mod",
        component: () =>
          import("@/views/configurations/settings/MessageOfDay.vue"),
      },
      {
        path: "target-year",
        name: "target year",
        component: () =>
          import("@/views/configurations/settings/TargetYear.vue"),
      },
      {
        path: "tranche",
        name: "tranche",
        component: () => import("@/views/configurations/settings/Tranche.vue"),
      },
      {
        path: "bank",
        name: "bank",
        component: () => import("@/views/configurations/settings/Bank.vue"),
      },
      {
        path: "claim-milestone",
        name: "claim milestone",
        component: () =>
          import("@/views/configurations/settings/ClaimMilestone.vue"),
      },
      {
        path: "blood-groups",
        name: "blood groups",
        component: () =>
          import("@/views/configurations/settings/BloodGroups.vue"),
      },
      {
        path: "educational-qualifications",
        name: "Educational Qualifications",
        component: () =>
          import(
            "@/views/configurations/settings/EducationalQualifications.vue"
          ),
      },

      {
        path: "ethnic-groups",
        name: "Ethnic Groups",
        component: () =>
          import("@/views/configurations/settings/EthnicGroups.vue"),
      },
      {
        path: "holidays",
        name: "Holidays",
        component: () => import("@/views/configurations/settings/Holidays.vue"),
      },
      {
        path: "religions",
        name: "Religions",
        component: () =>
          import("@/views/configurations/settings/Religions.vue"),
      },
      {
        path: "login-log",
        name: "login log",
        component: () => import("@/views/configurations/settings/LoginLog.vue"),
      },
      {
        path: "dashboard-widget",
        name: "dashboard widget",
        component: () =>
          import("@/views/configurations/settings/DashBoardWidget.vue"),
      },

      // {
      //   path: "assessor-organization",
      //   name: "assessor organization",
      //   component: () => import("@/views/Assessor/Organization.vue"),
      // },
    ],
  },
  {
    path: "/geo",
    component: () => import("@/layout/Geo.vue"),
    children: [
      {
        path: "country",
        name: "country",
        component: () => import("@/views/configurations/settings/Country.vue"),
      },
      {
        path: "geo-division",
        name: "geo division",
        component: () =>
          import("@/views/configurations/settings/GeoDivision.vue"),
      },
      {
        path: "geo-district",
        name: "geo district",
        component: () =>
          import("@/views/configurations/settings/GeoDistrict.vue"),
      },
      {
        path: "geo-sub-district",
        name: "geo sub district",
        component: () =>
          import("@/views/configurations/settings/GeoSubDistrict.vue"),
      },
      {
        path: "geo-tree",
        name: "geo tree",
        component: () => import("@/views/configurations/settings/GeoTree.vue"),
      },
    ],
  },
  {
    path: "/training-settings",
    component: () => import("@/layout/TrainingSetting.vue"),
    children: [
      {
        path: "assessment-type",
        name: "assessment type",
        component: () =>
          import("@/views/configurations/settings/AssessmentType.vue"),
      },
      {
        path: "industry-sectors",
        name: "Industry Sectors",
        component: () =>
          import("@/views/configurations/settings/IndustrySectors.vue"),
      },
      {
        path: "industry-sub-sectors",
        name: "Industry Sub Sectors",
        component: () =>
          import("@/views/configurations/settings/IndustrySubSectors.vue"),
      },
      {
        path: "training-milestone",
        name: "training milestone",
        component: () =>
          import("@/views/configurations/settings/TrainingMilestone.vue"),
      },

      {
        path: "training-target",
        name: "training target",
        component: () =>
          import("@/views/configurations/trainingProgram/TrainingTarget.vue"),
      },
      {
        path: "training-target-list",
        name: "training target list",
        component: () =>
          import(
            "@/views/configurations/trainingProgram/TrainingTargetList.vue"
          ),
      },
    ],
  },
  //course
  // {
  //   path: '/course-settings',
  //   component: () => import('@/layout/CourseSettings.vue'),
  //   children: [
  //     {
  //       path: 'course-type',
  //       name: 'course type',
  //       component: () =>
  //         import('@/views/configurations/courseSettings/CourseTypes.vue'),
  //     },
  //     {
  //       path: 'course-categories',
  //       name: 'course categories',
  //       component: () =>
  //         import('@/views/configurations/courseSettings/CourseCategories.vue'),
  //     },
  //     {
  //       path: 'course-sector',
  //       name: 'course sector',
  //       component: () =>
  //         import('@/views/configurations/courseSettings/CourseSector.vue'),
  //     },
  //   ],
  // },
  {
    path: "/training",
    component: () => import("@/layout/Training.vue"),
    children: [
      {
        path: "training-calendar",
        name: "training-calendar",
        component: () => import("@/views/training/trainingCalendar.vue"),
      },
      {
        path: "trainee-attendance",
        name: "trainee-attendance",
        component: () => import("@/views/training/traineeAttendance.vue"),
      },
      {
        path: "internship-trainee-attendance",
        name: "internship-trainee-attendance",
        component: () => import("@/views/training/internshipAttendance.vue"),
      },
      // {
      //   path: 'internship-attendance',
      //   name: 'internship-attendance',
      //   component: () => import('@/views/training/internshipAttendance.vue'),
      // },
      {
        path: "allowDropout-trainee",
        name: "allowDropout-trainee",
        component: () => import("@/views/training/allowDropoutTrainee.vue"),
      },
      {
        path: "attendance-report",
        name: "attendance-report",
        component: () => import("@/views/training/attendanceReport.vue"),
      },
      {
        path: "training-completion",
        name: "training-completion",
        component: () => import("@/views/training/trainingCompletion.vue"),
      },
      {
        path: "training-calendar-report",
        name: "training-calendar-report",
        component: () => import("@/views/training/TraineeCalendarReport.vue"),
      },
      // {
      //   path: 'trainee-attendance-report',
      //   name: 'trainee-attendance-report',
      //   component: () => import('@/views/training/traineeAttendanceReport.vue'),
      // },
    ],
  },
  //training program
  {
    path: "/training-program",
    component: () => import("@/layout/TrainingProgram.vue"),
    children: [
      {
        path: "program-designation",
        name: "Training Program",
        component: () =>
          import(
            "@/views/configurations/trainingProgram/ProgramDesignation.vue"
          ),
      },
      {
        path: "program-package",
        name: "program package",
        component: () =>
          import("@/views/configurations/trainingProgram/ProgramPackage.vue"),
      },
      {
        path: "program-name",
        name: "program name",
        component: () =>
          import("@/views/configurations/trainingProgram/ProgramName.vue"),
      },
      {
        path: "program-sector",
        name: "program sector",
        component: () =>
          import("@/views/configurations/trainingProgram/ProgramSector.vue"),
      },
      {
        path: "program-trade",
        name: "program trade",
        component: () =>
          import("@/views/configurations/trainingProgram/ProgramTrade.vue"),
      },
      {
        path: "program-type",
        name: "program type",
        component: () =>
          import("@/views/configurations/trainingProgram/ProgramType.vue"),
      },
      {
        path: "program-venue",
        name: "program venue",
        component: () =>
          import("@/views/configurations/trainingProgram/ProgramVenue.vue"),
      },
      {
        path: "program-organization",
        name: "program organization",
        component: () =>
          import(
            "@/views/configurations/trainingProgram/ProgramOrganization.vue"
          ),
      },
    ],
  },
  //entities
  {
    path: "/entities",
    component: () => import("@/layout/Entity.vue"),
    children: [
      {
        path: "entity-infos",
        name: "entity infos",
        component: () =>
          import("@/views/configurations/entities/EntityInfos.vue"),
      },
      {
        path: "entity-info-details",
        name: "entity info details",
        component: () =>
          import("@/views/configurations/entities/EntityInfoDetails.vue"),
      },
      {
        path: "entity-types",
        name: "entity types",
        component: () =>
          import("@/views/configurations/entities/EntityTypes.vue"),
      },
      {
        path: "entity-type-roles",
        name: "entity type roles",
        component: () =>
          import("@/views/configurations/entities/EntityTypeRoles.vue"),
      },
      {
        path: "entity-type-role-groups",
        name: "entity type role groups",
        component: () =>
          import("@/views/configurations/entities/EntityTypeRoleGroups.vue"),
      },
    ],
  },
  {
    path: "/report",
    component: () => import("@/layout/Report.vue"),
    children: [
      {
        path: "pending-statistics",
        name: "pending statistics",
        component: () => import("@/views/reports/PendingStatistics.vue"),
      },
      {
        path: "ethnicdisable",
        name: "Ethinic Minority & Disable Report",
        component: () => import("@/views/reports/EthnicDisable.vue"),
      },
      {
        path: "report-finder",
        name: "report finder",
        component: () => import("@/views/reports/ReportFinder.vue"),
      },
      {
        path: "batch-monitor",
        name: "batch monitor",
        component: () => import("@/views/reports/BatchReport.vue"),
      },
      {
        path: "batch-motor-driving",
        name: "batch motor driving",
        component: () => import("@/views/reports/BatchMotorDrivingReport.vue"),
      },
      // {
      //   path: 'student-report',
      //   name: 'student report',
      //   component: () => import('@/views/reports/StudentReport.vue'),
      // },
      {
        path: "course-student",
        name: "course student",
        component: () => import("@/views/reports/StudentList.vue"),
      },
      {
        path: "monitor-trainee-attendance",
        name: "trainee attendance",
        component: () =>
          import("@/views/reports/Monitoring_Trainee_Attendance.vue"),
      },
      {
        path: "datewise-trainee-attendance",
        name: "trainee attendance date wise",
        component: () => import("@/views/reports/Date_wise_attendance.vue"),
      },
      {
        path: "trainee-allownce-summary",
        name: "trainee allownce summary",
        component: () =>
          import("@/views/reports/Trainee_Allowance_Summary.vue"),
      },
      {
        path: "trainee-allowance-summary",
        name: "trainee-allowance-summary",
        component: () =>
          import("@/views/reports/trainee_allowance_summary_Report.vue"),
      },
      {
        path: "running-batch",
        name: "running batch",
        component: () => import("@/views/reports/RunningBatch.vue"),
      },
      {
        path: "all-batch",
        name: "all  batch",
        component: () => import("@/views/reports/AllBatch.vue"),
      },
      {
        path: "batch-wise-placement-summary",
        name: "batch-wise-summary",
        component: () =>
          import("@/views/reports/BatchWisePlacementSummaryReport.vue"),
      },
      {
        path: "trainee-job-placement-summary",
        name: "trainee-job-placement-summary",
        component: () =>
          import("@/views/reports/TraineeJobPlacementSummaryReport.vue"),
      },
      {
        path: "physicallydisabled",
        name: "physically disabled",
        component: () => import("@/views/reports/Phisically_Disable.vue"),
      },
      {
        path: "poor-trainee-summary",
        name: "poor trainee summary",
        component: () => import("@/views/reports/Poor.vue"),
      },
      {
        path: "ethnic-minority",
        name: "ethnic minority",
        component: () => import("@/views/reports/Ethnic_Minority.vue"),
      },
      {
        path: "ethnic-challenged-trainee",
        name: "ethnic challenged trainee",
        component: () => import("@/views/reports/EthnicChallengedTrainee.vue"),
      },
      {
        path: "gender-equity",
        name: "gender equity",
        component: () => import("@/views/reports/GenderEquity.vue"),
      },
      {
        path: "stipend-list-poor",
        name: "stipend list poor",
        component: () => import("@/views/reports/Stipend_List_Poor.vue"),
      },
      {
        path: "student-attendance-list",
        name: "student attendance",
        component: () => import("@/views/reports/Students_Attendance.vue"),
      },
      {
        path: "student-age-group-list",
        name: "student age group list",
        component: () => import("@/views/reports/Student_Age_Group_List.vue"),
      },
      {
        path: "course-summary",
        name: "course summary",
        component: () => import("@/views/reports/CourseSummary.vue"),
      },
      {
        path: "tot-course",
        name: "tot course",
        component: () => import("@/views/reports/TotCourse.vue"),
      },
      {
        path: "institute-enrollment-summary",
        name: "institute enrollment summary",
        component: () =>
          import("@/views/reports/InstituteEnrollmentSummary.vue"),
      },
      {
        path: "institute-enrollment-summary-report",
        name: "institute enrollment summary report",
        component: () =>
          import("@/views/reports/DPDSInstituteEnrollmentSummary.vue"),
      },
      {
        path: "batch-enrollment-summary",
        name: "batch enrollment summary",
        component: () => import("@/views/reports/BatchEnrollmentSummary.vue"),
      },
      {
        path: "motor-driving-course-summary",
        name: "Motor Driving Course Summary",
        component: () =>
          import("@/views/reports/MotorDrivingCourseSummary.vue"),
      },
      {
        path: "course-wise-training-activity",
        name: "Course Wise Training Activity",
        component: () =>
          import("@/views/reports/GenderWiseTrainingActivity.vue"),
      },
      {
        path: "course-trainningStatistics",
        name: "course trainningStatistics",
        component: () =>
          import("@/views/reports/CourseTrainningStatistics.vue"),
      },
      {
        path: "course-category-report",
        name: "course-category-report",
        component: () => import("@/views/reports/CategoryWishCourse.vue"),
      },
      {
        path: "on-going-course-summary",
        name: "on going course summary",
        component: () => import("@/views/reports/Ongoing_Course_Summary.vue"),
      },
      {
        path: "completed-course-summary",
        name: "completed course summary",
        component: () => import("@/views/reports/Completed_course_summary.vue"),
      },
      {
        path: "entity-type-roles",
        name: "entity type roles",
        component: () =>
          import("@/views/configurations/entities/EntityTypeRoles.vue"),
      },
      {
        path: "entity-type-role-groups",
        name: "entity type role groups",
        component: () =>
          import("@/views/configurations/entities/EntityTypeRoleGroups.vue"),
      },
      {
        path: "nid-report",
        name: "nid report",
        component: () => import("@/views/reports/NidReport.vue"),
      },
      {
        path: "bcn-report",
        name: "bcn report",
        component: () => import("@/views/reports/BcnReport.vue"),
      },
      {
        path: "active-status-history",
        name: "active status history",
        component: () => import("@/views/reports/ActiveStatusHistory.vue"),
      },
      {
        path: "cumulative-progress-report",
        name: "cumulative progress report",
        component: () => import("@/views/reports/CumulativeProgressNew.vue"),
      },

      {
        path: "district-trainee-summary",
        name: "districtTraineeSummary",
        component: () => import("@/views/reports/DistrictTrainee.vue"),
      },
      {
        path: "status-monitoring-report",
        name: "status-monitoring-report",
        component: () => import("@/views/reports/StatusMonitorReport.vue"),
      },
      {
        path: "institute-wise-training-statistic",
        name: "institute-wise-training-statistic",
        component: () =>
          import("@/views/reports/InstituteTrainningStatistics.vue"),
      },
      {
        path: "course-wise-break-down",
        name: "course-wise-break-down",
        component: () => import("@/views/reports/CourseWiseBreakDown.vue"),
      },
      {
        path: "pending-activities",
        name: "pending activities",
        component: () => import("@/views/reports/PendingActivities.vue"),
      },
      {
        path: "implementation-progress",
        name: "implementation progress",
        component: () => import("@/views/reports/ImplementationProgress.vue"),
      },
      {
        path: "mid-level-course",
        name: "mid level course",
        component: () => import("@/views/reports/MidLevelCourse.vue"),
      },
      {
        path: "unique-course",
        name: "unique course",
        component: () => import("@/views/reports/UniqueCourse.vue"),
      },
      {
        path: "course-activity",
        name: "course activity",
        component: () => import("@/views/reports/CourseActivity.vue"),
      },
      {
        path: "disburse-statement",
        name: "disburse-statement",
        component: () => import("@/views/reports/DisburseStatement.vue"),
      },
      {
        path: "asset-register",
        name: "assest-register",
        component: () => import("@/views/reports/AssetRegister.vue"),
      },
      {
        path: "pending-asset-register",
        name: "pending assest-register",
        component: () => import("@/views/reports/PendingAssetRegister.vue"),
      },
      {
        path: "new-asset-register",
        name: "new-assest-register",
        component: () => import("@/views/reports/NewAssetRegister.vue"),
      },
      {
        path: "add-supplier",
        name: "add-supplier",
        component: () => import("@/views/reports/NewSuplier.vue"),
      },
      {
        path: "claim-3-job-placement-summary",
        name: "claim 3 job placement summary",
        component: () =>
          import("@/views/reports/Claim3JobPlacementSummaryReport.vue"),
      },
      {
        path: "overseas-job-placement-summary",
        name: "overseas job placement summary",
        component: () => import("@/views/reports/OverseasPlacementSummary.vue"),
      },
    ],
  },
  {
    path: "/components",
    component: () => import("@/layout/Components.vue"),
    children: [
      {
        path: "list-view",
        name: "list view",
        component: () => import("@/views/others/components/Listview.vue"),
      },
      {
        path: "buttons",
        name: "buttons",
        component: () => import("@/views/others/components/Buttons.vue"),
      },
      {
        path: "tree-view",
        name: "tree view",
        component: () => import("@/views/others/components/Treeview.vue"),
      },
      {
        path: "image-crop",
        name: "image crop",
        component: () => import("@/views/others/components/Imagecrop.vue"),
      },
      {
        path: "file-upload",
        name: "file upload",
        component: () => import("@/views/others/components/Fileupload.vue"),
      },
      {
        path: "drag",
        name: "drag & drop",
        component: () => import("@/views/others/components/Drag.vue"),
      },
      {
        path: "forms",
        name: "forms",
        component: () => import("@/views/others/components/Forms.vue"),
      },
    ],
  },
  //Assessor
  {
    path: "/assessor",
    component: () => import("@/layout/Assessor.vue"),
    children: [
      {
        path: "add-assessor",
        name: "add assessor",
        component: () => import("@/views/Assessor/AddAssessor.vue"),
      },
      {
        path: "assessor-organization",
        name: "assessor organization",
        component: () => import("@/views/Assessor/Organization.vue"),
      },
      {
        path: "assessor-list",
        name: "assessor list",
        component: () => import("@/views/Assessor/List.vue"),
      },
      {
        path: "inactive-assessor-list",
        name: "inactive assessor list",
        component: () => import("@/views/Assessor/InactiveList.vue"),
      },
      {
        path: "assessor-advance-search",
        name: "assessor advance search",
        component: () => import("@/views/Assessor/AdvanceSearch.vue"),
      },
      // {
      //   path: "setting",
      //   name: "setting",
      //   component: () => import("@/layout/Assessor.vue"),
      //   children: [
      //     {
      //       path: "assessor-organization",
      //       name: "assessor organization",
      //       component: () => import("@/views/Assessor/Organization.vue"),
      //     },
      //   ],
      // },
    ],
  },
  //Employee
  {
    path: "/employee",
    component: () => import("@/layout/Employee.vue"),
    children: [
      {
        path: "new-nid",
        name: "new nid",
        component: () => import("@/views/Employee/AddNewNid.vue"),
      },
      {
        path: "new-employee",
        name: "new employee",
        component: () => import("@/views/Employee/NewEmployee.vue"),
      },
      {
        path: "employee-list",
        name: "employee list",
        component: () => import("@/views/Employee/EmployeeList.vue"),
      },
    ],
  },
  {
    path: "/fieldvisit",
    component: () => import("@/layout/Fieldvisit.vue"),
    children: [
      {
        path: "add-visit-info",
        name: "add visit info",
        component: () => import("@/views/others/fieldvisit/AddVisitInfo.vue"),
      },
      {
        path: "visit-list",
        name: "visit list",
        component: () => import("@/views/others/fieldvisit/VisitList.vue"),
      },
      // {
      //   path: 'edit-visit-info',
      //   name: 'edit visit info',
      //   component: () => import('@/views/others/fieldvisit/EditVisitList.vue'),
      // },
      {
        path: "visit-report",
        name: "visit report",
        component: () => import("@/views/others/fieldvisit/VisitReport.vue"),
      },
    ],
  },
  //Training Institute
  {
    path: "/training-institute",
    component: () => import("@/layout/Training_institute.vue"),
    children: [
      {
        path: "new-institute",
        name: "new institute",
        component: () => import("@/views/Training_Institute/NewInstitute.vue"),
      },

      {
        path: "institute-detail",
        name: "institute detail",
        component: () =>
          import("@/views/Training_Institute/NewInstituteDetail.vue"),
      },
      {
        path: "institute-search",
        name: "institute search",
        component: () =>
          import("@/views/Training_Institute/InstituteSearch.vue"),
      },
      {
        path: "institute-dynamic-search",
        name: "institute dynamic search",
        component: () => import("@/views/Training_Institute/DTSearch.vue"),
      },
      {
        path: "institute-target",
        name: "institute target",
        component: () =>
          import("@/views/Training_Institute/InstituteTarget.vue"),
      },
      {
        path: "mapping",
        name: "mapping",
        component: () => import("@/views/Training_Institute/InstituteMap.vue"),
        children: [
          {
            path: "course",
            name: "map course component",
            component: () =>
              import("@/views/Training_Institute/mapping/MapCourse.vue"),
          },
          {
            path: "trainer",
            name: "map trainer component",
            component: () =>
              import("@/views/Training_Institute/mapping/MapTrainer.vue"),
          },
          {
            path: "assessor",
            name: "map assessor component",
            component: () =>
              import("@/views/Training_Institute/mapping/MapAssessor.vue"),
          },
        ],
      },
      {
        path: "pending-institute-target",
        name: "pending institute target",
        component: () =>
          import("@/views/Training_Institute/PendingInstituteTarget.vue"),
      },
      {
        path: "institute-employee/:id",
        name: "institute employee",
        component: () =>
          import("@/views/Training_Institute/InstituteEmployee.vue"),
      },
      {
        path: "institute-profile/:id",
        name: "instituteEmployeeProfiles",
        component: () => import("@/views/Employee/EmployeeProfile.vue"),
      },
      {
        path: "assessor",
        name: "map assessor",
        component: () =>
          import("@/views/Training_Institute/mapping/MapAssessor.vue"),
      },
      {
        path: "institute-list/:id",
        name: "institute view",
        component: () =>
          import("@/views/Training_Institute/singleInstitute.vue"),
      },
    ],
  },
  {
    path: "/certificate",
    component: () => import("@/layout/Certificate.vue"),
    children: [
      {
        path: "certificate-dashboard",
        name: "certificate dashboard",
        component: () =>
          import("@/views/certification/CertificateDashboard.vue"),
      },
      {
        path: "search",
        name: "search",
        component: () => import("@/views/certification/Search.vue"),
      },
      {
        path: "training-certificate",
        name: "training certificate",
        component: () =>
          import("@/views/certification/TrainingCertificate.vue"),
      },

      {
        path: "training-certificate-information",
        name: "training certificate information",
        component: () =>
          import("@/views/certification/TrainingCertificateInformation.vue"),
      },
      {
        path: "training-certificate-advance-search",
        name: "training-certificate-advance-search",
        component: () => import("@/views/certification/AdvanceSearch.vue"),
      },
      {
        path: "training-certificate-configuration",
        name: "training-certificate-configuration",
        component: () =>
          import("@/views/certification/TrainingCertificateConfig.vue"),
      },
      {
        path: "training-certificate-list",
        name: "training-certificate-list",
        component: () =>
          import("@/views/certification/TrainingCertificateList.vue"),
      },
      {
        path: "training-certificate-view/:id",
        name: "training certificate view",
        component: () =>
          import("@/views/certification/TrainingCertificateFormat.vue"),
      },
      {
        path: "training-certificate-download",
        name: "training-certificate-download",
        component: () =>
          import("@/views/certification/CertificateDownload.vue"),
      },
      {
        path: "certificate-template",
        name: "Certificate Template",
        component: () =>
          import("@/views/certification/CertificateTemplate.vue"),
      },
    ],
  },
  {
    path: "/job-placement",
    component: () => import("@/layout/Job_placement.vue"),
    children: [
      {
        path: "dashboard",
        name: "dash board",
        component: () => import("@/views/job-placement/DashBoard.vue"),
      },
      {
        path: "list",
        name: "list",
        component: () => import("@/views/job-placement/JobPlacementList.vue"),
      },
      {
        path: "enrolled-trainee",
        name: "enroll trainee",
        component: () => import("@/views/job-placement/EnrolledTrainee.vue"),
      },
      {
        path: "certificate-complete-trainee",
        name: "certificate complete trainee",
        component: () =>
          import("@/views/job-placement/CertificateCompleteTrainee.vue"),
      },
      {
        path: "job-tracking",
        name: "job tracking",
        component: () => import("@/views/job-placement/JobTracking.vue"),
      },
      {
        path: "self-employment",
        name: "Self Employment",
        component: () => import("@/views/job-placement/SelfEmployment.vue"),
      },
      {
        path: "wages-employee",
        name: "Wages Employee",
        component: () => import("@/views/job-placement/WagesEmployee.vue"),
      },
      {
        path: "upskill-information",
        name: "upskill information",
        component: () => import("@/views/job-placement/UpSkillInformation.vue"),
      },
      {
        path: "job-history",
        name: "job history",
        component: () => import("@/views/job-placement/JobHistory.vue"),
      },
      {
        path: "job-release",
        name: "job release",
        component: () => import("@/views/job-placement/JobRelease.vue"),
      },
      {
        path: "show-placement",
        name: "Show Placement",
        component: () => import("@/views/job-placement/PlacementHistory.vue"),
      },
      {
        path: "employment-information",
        name: "employment information",
        component: () =>
          import("@/views/reports/BatchWisePlacementSummaryReport.vue"),
      },
      {
        path: "employed-trainee-summary/:batch_info_id/:is_3_month",
        name: "employed trainee symmary",
        component: () =>
          import("@/views/reports/summary/EmployedTraineeSummary.vue"),
      },
    ],
  },
  {
    path: "/assessment",
    component: () => import("@/layout/Assessment.vue"),
    children: [
      {
        path: "assessment-dashboard",
        name: "Assessment Dashboard",
        component: () => import("@/views/Assessment/AssessmentDashboard.vue"),
      },
      {
        path: "assessment-search",
        name: "Assessment Search",
        component: () => import("@/views/Assessment/AssessmentSearch.vue"),
      },
      {
        path: "training-assessment",
        name: "Training Assessment",
        component: () => import("@/views/Assessment/TrainingAssessment.vue"),
      },
      {
        path: "assessment-list",
        name: "Assessment List",
        component: () => import("@/views/Assessment/AssessmentList.vue"),
      },
      {
        path: "training-reassessment",
        name: "Training Reassessment",
        component: () => import("@/views/Assessment/TrainingReassessment.vue"),
      },
      {
        path: "assesment-advance-search",
        name: "Assesment Advance Search",
        component: () => import("@/views/Assessment/AdvanceSearch.vue"),
      },
    ],
  },
  {
    path: "/enrollment",
    component: () => import("@/layout/Enrollment.vue"),
    children: [
      {
        path: "enrollment-summary",
        name: "Enrollment Summary",
        component: () => import("@/views/enrollment/EnrollmentSummary.vue"),
      },
      {
        path: "attendance-performance",
        name: "Attendance Performance",
        component: () => import("@/views/enrollment/AttendancePerformance.vue"),
      },
      {
        path: "training-license",
        name: "Training License",
        component: () => import("@/views/enrollment/TrainingLicense.vue"),
      },
    ],
  },
  {
    path: "/training-program-list",
    component: () => import("@/layout/TrainingProgramList.vue"),
    children: [
      {
        path: "add-program",
        name: "add-program-training",
        component: () => import("@/views/training_program/AddProgram.vue"),
      },
      {
        path: "advanced-program-report",
        name: "program report",
        component: () => import("@/views/training_program/AdvancedReport.vue"),
      },
    ],
  },
  {
    path: "/stipend",
    component: () => import("@/layout/Stipend.vue"),
    children: [
      {
        path: "stipend-eligibility",
        name: "Stipend Eligibility Check",
        component: () =>
          import("@/views/others/Stipend/StipendEligibility.vue"),
      },
      {
        path: "stipend-course-list",
        name: "stipend course list",
        component: () => import("@/views/others/Stipend/stipendCourseList.vue"),
      },
      {
        path: "trainee-migration",
        name: "Trainee Migration",
        component: () => import("@/views/others/Stipend/TraineeMigration.vue"),
      },
      {
        path: "stipend-trainee-view/:entity/:institute/:tranche/:start_date/:end_date",
        name: "stipend trainee view",
        component: () =>
          import("@/views/others/Stipend/StipendTraineeView.vue"),
      },
      {
        path: "stipend-trainee-view",
        name: "stipend trainee view",
        component: () =>
          import("@/views/others/Stipend/StipendTraineeView.vue"),
      },
      {
        path: "stipend-list-trainee-view",
        name: "trainee view list",
        component: () =>
          import("@/views/others/Stipend/StipendListTraineeView.vue"),
      },
      {
        path: "stipend-disbursed-trainee-view",
        name: "stipend-disbursed-trainee-view",
        component: () =>
          import("@/views/others/Stipend/StipendListDisbursedTraineeView.vue"),
      },
      {
        path: "trainee-selection",
        name: "Training Selection",
        component: () => import("@/views/others/Stipend/TrainingSelection.vue"),
      },
      {
        path: "selected-trainee",
        name: "Selected Trainee",
        component: () => import("@/views/others/Stipend/SelectedTrainee.vue"),
      },
      {
        path: "disbursement-data",
        name: "Upload Disbursement Data",
        component: () =>
          import("@/views/others/Stipend/UploadDisbursementData.vue"),
      },
      // {
      //   path: "stipend-management-report",
      //   name: "Stipend Management Report",
      //   component: () => import("@/views/others/Stipend/StipendManagementReport.vue"),
      // },
      {
        path: "disbursement-report",
        name: "Disbursement Report",
        component: () =>
          import("@/views/others/Stipend/DisbursementReport.vue"),
      },
      {
        path: "stipend-payment-summary",
        name: "Stipend Payment Summary",
        component: () =>
          import("@/views/others/Stipend/StipendPaymentSummary.vue"),
      },
      {
        path: "stipend-activity-report-association",
        name: "Stipend Activity Report Association",
        component: () =>
          import("@/views/others/Stipend/StipendActivityReportAssociation.vue"),
      },
      {
        path: "stipend-activity-report-district",
        name: "Stipend Activity Report District",
        component: () =>
          import("@/views/others/Stipend/StipendActivityReportDistrict.vue"),
      },
    ],
  },
  {
    path: "/training-partners-info",
    component: () => import("@/layout/BillingTrainingPT.vue"),
    children: [
      {
        path: "claim-1-bill",
        name: "claim bill_1",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/Claim_1_Bill_submit.vue"
          ),
      },
      {
        path: "claim-1-submitted-bills",
        name: "claim submitted bill_1",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/Claim_1_Submitted_bills.vue"
          ),
      },
      {
        path: "claim-2-bill",
        name: "claim bill_2",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/Claim_2_Bill_submit.vue"
          ),
      },
      {
        path: "claim-2-submitted-bills",
        name: "claim submitted bill_2",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/Claim_2_Submitted_bills.vue"
          ),
      },
      {
        path: "reassessment-bills-submit",
        name: "reassessment bill submit",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/Reassessment_Bill_Submit.vue"
          ),
      },
      {
        path: "reassessment-submitted-bills",
        name: "reassessment submitted bills",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/Reassessment_Submitted_Bill.vue"
          ),
      },
      {
        path: "claim-3-bill",
        name: "claim bill_3",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/Claim_3_Bill_submit.vue"
          ),
      },
      {
        path: "claim-3-submitted-bills",
        name: "claim submitted bill_3",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/Claim_3_Submitted_bills.vue"
          ),
      },
      {
        path: "manual-bill-adjustment",
        name: "manual bill adjustment training",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/ManualBillAdjustment.vue"
          ),
      },
      {
        path: "enrollment-bill-adjustment",
        name: "enrollment bill adjustment training",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/EnrollmentBillAdjustment.vue"
          ),
      },
      {
        path: "certification-bill-adjustment",
        name: "certification bill adjustment training",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/CertificationBillAdjustment.vue"
          ),
      },
      {
        path: "employment-bill-adjustment",
        name: "employment bill adjustment training",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/EmploymentBillAdjustment.vue"
          ),
      },
      {
        path: "archive-bill",
        name: "Archive Bill",
        component: () =>
          import(
            "@/views/Billing/Trainers Partner Info/Bill_archive.vue"
          ),
      },
      // {
      //   path: "bill-adjustment",
      //   name: "bill adjustment training",
      //   component: () => import("@/views/Billing/Trainers Partner Info/BillAdjustment.vue"),
      // },
    ],
  },
  {
    path: "/seip-bill-info",
    component: () => import("@/layout/SeipBillInfo.vue"),
    children: [
      {
        path: "claim-1-submitted-bills",
        name: "claim bill_1 seip",
        component: () =>
          import("@/views/Billing/SEIP/Claim_1_submittedBill.vue"),
      },
      {
        path: "claim-1-approved-bills",
        name: "claim approved bill_1",
        component: () =>
          import("@/views/Billing/SEIP/Claim_1_approvedBill.vue"),
      },
      {
        path: "claim-1-submitted-bill-list/:entity_id/:tranche_id/:entity_short_name",
        name: "claim_1 submitted bill list",
        component: () =>
          import("@/views/Billing/SEIP/Claim_1_submittedBillList.vue"),
      },
      {
        path: "claim-1-approved-bill-list/:entity_id/:tranche_id/:entity_short_name",
        name: "claim_1 approved bill list",
        component: () =>
          import("@/views/Billing/SEIP/Claim_1_approvedBillList.vue"),
      },
      {
        path: "claim-2-submitted-bills",
        name: "claim bill_2 seip",
        component: () =>
          import("@/views/Billing/SEIP/Claim_2_submittedBill.vue"),
      },
      {
        path: "claim-2-submitted-bill-list/:entity_id/:tranche_id/:entity_short_name",
        name: "claim_2 submitted bill list",
        component: () =>
          import("@/views/Billing/SEIP/Claim_2_submittedBillList.vue"),
      },
      {
        path: "claim-2-approved-bills",
        name: "claim submitted bill_2 seip",
        component: () =>
          import("@/views/Billing/SEIP/Claim_2_approvedBill.vue"),
      },
      {
        path: "claim-2-approved-bill-list/:entity_id/:tranche_id/:entity_short_name",
        name: "claim_2 approved bill list",
        component: () =>
          import("@/views/Billing/SEIP/Claim_2_approvedBillList.vue"),
      },

      {
        path: "claim-2-reassessment-submitted-bills",
        name: "claim 2 reassessment submitted bills",
        component: () =>
          import("@/views/Billing/SEIP/Claim_2_Reassessment_submittedBill.vue"),
      },
      {
        path: "claim-2-reassessment-submitted-bill-list/:entity_id/:tranche_id/:entity_short_name",
        name: "claim_2 reassessment submitted bill list",
        component: () =>
          import(
            "@/views/Billing/SEIP/Claim_2_Reassessment_submittedBillList.vue"
          ),
      },
      {
        path: "claim-2-reassessment-approved-bills",
        name: "claim reassessment submitted bill_2 seip",
        component: () =>
          import("@/views/Billing/SEIP/Claim_2_Reassessment_approvedBill.vue"),
      },
      {
        path: "claim-2-reassessment-approved-bill-list/:entity_id/:tranche_id/:entity_short_name",
        name: "claim_2 reassessment approved bill list",
        component: () =>
          import(
            "@/views/Billing/SEIP/Claim_2_Reassessment_approvedBillList.vue"
          ),
      },
      {
        path: "claim-3-submitted-bills",
        name: "claim bill_3 seip",
        component: () =>
          import("@/views/Billing/SEIP/Claim_3_submittedBill.vue"),
      },
      {
        path: "claim-3-submitted-bill-list/:entity_id/:tranche_id/:entity_short_name",
        name: "claim_3 submitted bill list",
        component: () =>
          import("@/views/Billing/SEIP/Claim_3_submittedBillList.vue"),
      },
      {
        path: "claim-3-approved-bills",
        name: "claim submitted bill_3 seip",
        component: () =>
          import("@/views/Billing/SEIP/Claim_3_approvedBill.vue"),
      },
      {
        path: "claim-3-approved-bill-list/:entity_id/:tranche_id/:entity_short_name",
        name: "claim_3 approved bill list",
        component: () =>
          import("@/views/Billing/SEIP/Claim_3_approvedBillList.vue"),
      },
      {
        path: "manual-bill-adjustment",
        name: "manual bill adjustment seip",
        component: () =>
          import("@/views/Billing/SEIP/ManualBillAdjustment.vue"),
      },
      {
        path: "bill-adjustment",
        name: "bill adjustment seip",
        component: () => import("@/views/Billing/SEIP/BillAdjustment.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
          import("@/views/crafted/modals/general/InviteFriends.vue"),
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/authentication/Error500.vue"),
  },
  {
    path: "/trainee-register",
    name: "Trainee Registration",
    component: () => import("@/views/trainee/self-reg.vue"),
    props: true,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import("@/views/privacy.vue"),
    props: true,
  },
  //open url

  {
    path: "/course-summary/:is_motodriving/:tranche/:entity",
    name: "course symmary by association",
    component: () =>
      import("@/views/DashBoard/summary/CourseSummaryAssociation.vue"),
  },
  {
    path: "/institute-summary/:is_motodriving/:tranche/:entity",
    name: "institute wise training summary",
    component: () =>
      import("@/views/DashBoard/summary/InstituteSummaryAssociation.vue"),
  },
  {
    path: "/course-institute-summary/:is_motodriving/:tranche/:entity/:course",
    name: "course institute wise training summary",
    component: () =>
      import("@/views/DashBoard/summary/InstituteSummaryCourse.vue"),
  },
  {
    path: "/batch-summary/:is_motodriving/:tranche/:entity/:course/:institute",
    name: "batch wise training summary",
    component: () =>
      import("@/views/DashBoard/summary/BatchwiseSummaryAssociation.vue"),
  },
  {
    path: "/batch-trainee-summary/:batch_id/:parent_id/:child_id/",
    name: "batch wise summary",
    component: () =>
      import("@/views/DashBoard/summary/BatchwiseTraineeSummary.vue"),
  },
  {
    path: "/pending-activity-summary/:parent_id",
    name: "batch wise trainee summary",
    component: () =>
      import("@/views/DashBoard/summary/PendingActivitySummary.vue"),
  },
  {
    path: "/institute-course-summary/:is_motodriving/:tranche/:entity/:institute",
    name: "institute wise course training summary",
    component: () =>
      import("@/views/DashBoard/summary/CourseSummaryInstitute.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
